var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "panel panel-flat mg-bt-0",
        staticStyle: { border: "none" }
      },
      [
        _c(
          "div",
          {
            staticClass: "panel-body panel-body-center mg-bt-0",
            staticStyle: { padding: "0px 10px" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row panel-detail",
                staticStyle: { width: "100%", "text-align": "left" }
              },
              [
                _c("div", { staticClass: "col-lg-12 inputDiv" }, [
                  _c("div", { staticClass: "row col-lg-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-8",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-lg-3 control-label ta-lf" },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsFieldMap.noticeTitle) + " :"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ta-lf",
                            staticStyle: { "margin-bottom": "10px" }
                          },
                          [
                            _c("div", { staticClass: "upl_span" }, [
                              _vm._v(
                                " " + _vm._s(_vm.noticeInfo.noticeTitle) + " "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row col-lg-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-2 form-group",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "col-lg-3 control-label ta-lf" },
                          [_vm._v(_vm._s(_vm.detailsFieldMap.oriFileNm) + " :")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ta-lf",
                            staticStyle: { "margin-bottom": "10px" }
                          },
                          [
                            _c("div", { staticClass: "upl_span" }, [
                              _c(
                                "a",
                                {
                                  attrs: { fileDownloadBtn: "" },
                                  on: { click: _vm.fileDownload }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.noticeInfo.oriFileNm) + " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row col-lg-12 form-group",
                      staticStyle: { "padding-right": "0px" }
                    },
                    [
                      _c("ckeditor", {
                        attrs: { config: _vm.editorConfig },
                        model: {
                          value: _vm.noticeInfo.noticeCont,
                          callback: function($$v) {
                            _vm.$set(_vm.noticeInfo, "noticeCont", $$v)
                          },
                          expression: "noticeInfo.noticeCont"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("최신 공지사항")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }